export const formErrorMessages: { [key: string]: string } = {
  invalidPhoneNumber: 'Невірний номер телефону',
  invalidPhoneLength: 'Невірний номер телефону',
  required: 'Поле обов`язкове для заповнення',
  invalidRnokpp: 'Невірний код',
  maxlength: 'Невірний формат',
  invalidIban: 'Некорректный IBAN',
  invalidNumber: 'Значення маэ бути числович',
  invalidUnzr: 'Невірний номер',
  invalidPassport: 'Невірний формат',
  email: 'Невірний email',
  invalidEdrpou: 'Невірний ЄДРПОУ',
  invalidCharacterSet: 'Невірна мова назви. Зверніть увагу на поле "алгоритм підпису" з попереднього кроку.',
}
