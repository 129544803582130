import { formErrorMessages } from '@app/core/constants/form-error-messages'

import { Pipe, PipeTransform } from '@angular/core'
import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms'

@Pipe({
  name: 'controlError',
  standalone: true,
  pure: true,
})
export class ControlErrorPipe implements PipeTransform {
  defaultMessage = 'Invalid field'

  transform(value: string | null, control: FormControl | AbstractControl | null): string {
    if (!control) {
      return ''
    }

    if (control.valid) {
      return ''
    }

    console.log(control)
    try {
      const errorsMessage = control.errors ? this.getMessage(control.errors) : this.defaultMessage

      control.markAsDirty()
      control.updateValueAndValidity()

      return errorsMessage
    } catch (e) {
      return this.defaultMessage
    }
  }

  private getMessage(errors: ValidationErrors): string {
    const errorKey = Object.keys(errors)[0]
    const errorMessage = formErrorMessages[errorKey]

    return errorMessage || this.defaultMessage
  }
}
